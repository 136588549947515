import React from 'react';
import get from 'lodash.get';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { sortByCreatedAt } from 'helpers/sort';
import { getBlogPostUrl } from 'helpers/url';

import TOPICS from 'helpers/blogTopics';

import { Container, ColsArticles } from '@matchbox-mobile/kenra-storybook';

export default function BlogArticles({ items }) {
  if (!Array.isArray(items) || items.length === 0) return null;
  let sortedItems = items.map(item => ({
    href: getBlogPostUrl(item.contentful_id),
    img: get(item, 'image.localFile.publicURL'),
    type: item.topic,
    typeColor: get(TOPICS, `${item.topic}.color`),
    authorImg: get(item, 'authorImage.localFile.publicURL'),
    authorName: item.author,
    title: item.title,
    text: `<p>${get(item, 'shortText.shortText')}</p>`,
    // date: `${formatDistanceToNow(parseISO(item.createdAt))} ago`,
  }));

  return (
    <>
      <Container>
        <ColsArticles
          rows={[
            {
              items: sortedItems.slice(0, 2),
            },
            {
              styleSmall: true,
              items: sortedItems.slice(2),
            },
          ]}
        />
      </Container>
    </>
  );
}
