import React from 'react';
import get from 'lodash.get';
import { getBlogPostUrl } from 'helpers/url';

import {
  Slider,
  SliderInfo,
  SharedStyles,
} from '@matchbox-mobile/kenra-storybook';

const { StSliderInfo, StSliderInfoText, StSliderInfoBg } = SliderInfo;
const { StLinkMore } = SharedStyles;

export default function BlogTopSlider({ items }) {
  return (
    <Slider>
      {items.map((item, index) => (
        <StSliderInfo key={index}>
          <StSliderInfoBg
            style={{
              backgroundImage: `url(${get(item, 'image.localFile.publicURL')})`,
            }}
          />
          <StSliderInfoText>
            <h3>{item.title}</h3>
            <hr />
            <p>{get(item, 'shortText.shortText')}</p>
            <StLinkMore>
              <a href={getBlogPostUrl(item.contentful_id)}>Shop</a>
            </StLinkMore>
          </StSliderInfoText>
        </StSliderInfo>
      ))}
    </Slider>
  );
}
