import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Blog from 'components/Blog';

export default function BlogPage(props) {
  let {
    data: {
      allContentfulBlogPost: { nodes },
    },
  } = props;

  return (
    <Layout>
      <Blog items={nodes} />
    </Layout>
  );
}

export const query = graphql`
  query {
    allContentfulBlogPost {
      nodes {
        ...ContentfulBlogPostFields
      }
    }
  }
`;
