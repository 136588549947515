import React from 'react';

import LatestVideos from 'blocks/LatestVideos';

import BlogNavigation from './BlogNavigation';
import BlogTopSlider from './BlogTopSlider';
import BlogArticles from './BlogArticles';

import { Spacing } from '@matchbox-mobile/kenra-storybook';

export const TypesEnum = {
  TRENDING: 'trending',
  HOWTO: 'howto',
  CREATORS: 'creators',
  INSPIRATION: 'inspiration',
};

export default function Blog({ items }) {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (url.split('?filter=').length > 1) {
    const type = url.split('?filter=')[1];
    let filterType = '';
    switch (type) {
      case TypesEnum.TRENDING:
        filterType = 'Trending';
        break;
      case TypesEnum.HOWTO:
        filterType = 'How-to';
        break;
      case TypesEnum.CREATORS:
        filterType = 'Creators';
        break;
      case TypesEnum.INSPIRATION:
        filterType = 'Inspiration';
        break;
      default:
    }
    if (filterType !== '') {
      items = items.filter(item => {
        console.log(
          filterType,
          '=',
          item.topic + '?',
          filterType === item.topic
        );
        return filterType === item.topic;
      });
    }
  }

  return (
    <>
      <Spacing removeSpaceTop>
        <BlogTopSlider items={items.filter(item => item.featured)} />
      </Spacing>
      <Spacing>
        <BlogNavigation />
      </Spacing>
      <Spacing>
        <BlogArticles items={items} />
      </Spacing>
      <Spacing>
        <LatestVideos title="Tutorials" />
      </Spacing>
    </>
  );
}
